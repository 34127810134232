//展示Ppt内容组件
<template>
    <div class="study_ppt">
        <!-- 内容布局 -->
        <div class="content" v-if="!showLoading">
            <!-- 左边的索引列表 -->
            <div class="left_list">
                <div :class="['item_list', { select_bg: activePos == index }]" v-for="(item, index) in pptList" :key="index" @click.stop="changePpt(item, index)">
                    <img class="video_img" :src="item.pptCover" />
                    <div class="video_name">{{ item.pptName }}</div>
                </div>
            </div>

            <!-- 右边的ppt展示 -->
            <div class="right_content">
                <!-- 图片分 -->
                <!-- indicator-position 指示器的位置 默认显示在内部  outside/none -->
                <el-carousel @change="change" :autoplay="false" indicator-position="none" :initial-index="currPage" class="show_carousel">
                    <el-carousel-item v-for="(item, index) in pptArr" :key="index">
                        <img :src="item.img" style="cursor: pointer" preview />
                    </el-carousel-item>
                </el-carousel>
                <!-- 指示器显示 -->
                <div class="txt_indicator">{{ currPos + 1 }}/{{ pptArr.length }}</div>
            </div>
        </div>
        <!-- 加载框 -->
        <Loading v-if="showLoading"></Loading>
    </div>
</template>
<script>
//导入Ppt排序的问题
import { pptArrSort } from "../../utils/Arr.js";
export default {
    name: "PptStudy",
    data() {
        return {
            //左边的PPT播放列表
            pptList: [],
            //每个小节对应的PPT列表
            pptArr: [],
            //左边列表选中的位置
            activePos: 0,
            //底部显示的页面位置
            currPos: 0,
            showLoading: true,
            //初始状态激活的幻灯片的索引，从 0 开始
            currPage: 0,
        };
    },
    created() {
        //请求数据
        this.httpData();
    },
    methods: {
        //获取列表数据
        async httpData() {
            let param = this.getHttpParams();
            param.type = 4;
            param.curriculumCenterId = this.curriculumCenterId;
            param.curriculumChapterId = this.curriculumChapterId;
            param.chapterClassId = this.chapterClassId;
            param.sectionId = 1;
            let res = await this.$http.fetchPost(
                this.$api.LESSON_CENTER,
                param
            );
            this.showLoading = false;
            if (res.data.state == 200) {
                //表示请求成功
                let serverArr = res.data.data[0].learningContent;

                //取出左边列表的所有数据
                this.pptList = serverArr;

                //取出右边需要展示的列表,默认展示第一个数据
                this.pptArr = pptArrSort(serverArr[0].list);
            } else {
                //直接将服务器返回的错误内容弹出提示
                this.showErrorMsg(this.getMesage(res.data.state));
            }
        },

        //幻灯片改变的方法
        change(pos) {
            this.currPos = pos;
        },
        //左边列表条目的点击事件
        changePpt(item, index) {
            if (this.activePos != index) {
                this.activePos = index;
                //在同步一下右边列表的数据
                this.pptArr = pptArrSort(item.list);
                //同步底部的页码显示
                this.currPos = 0;
            }
        },
    },
    props: ["curriculumCenterId", "curriculumChapterId", "chapterClassId"],
    computed: {},
    components: {},
};
</script>
<style lang="scss" scoped>
.study_ppt {
    background: black;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    .content {
        width: 90%;
        margin: 0 auto;
        display: flex;
        height: 582px;
        //左边的索引List部分
        .left_list {
            margin-right: 16px;
            height: 100%;
            background: rgba($color: #abacae, $alpha: 0.2);
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            width: 220px;
            overflow-x: hidden;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            .item_list {
                height: 88px;
                padding: 16px 0;
                display: flex;
                align-items: center;
                cursor: pointer;
                //视频图片
                .video_img {
                    margin-left: 14px;
                    width: 58px;
                    height: 58px;
                    border-radius: 2px;
                    object-fit: cover;
                }
                //视频名称
                .video_name {
                    color: white;
                    flex: 1;
                    margin: 0 14px;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .item_list:hover {
                background: rgba($color: #000000, $alpha: 0.4);
            }

            //选中的背景
            .select_bg {
                background: rgba($color: #000000, $alpha: 0.4);
            }
        }

        // 右边的展示PPT列表部分
        .right_content {
            flex: 1;
            display: flex;
            flex-direction: column;

            .show_carousel {
                flex: 1;
            }

            ::v-deep .el-carousel__container {
                top: 0px;
                height: 100%;
            }
            .el-carousel__item img {
                position: absolute;
                width: 100%;
                height: 520px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: contain;
            }

            ::v-deep .el-carousel__arrow {
                background: rgba($color: $common_bg, $alpha: 0.4);
            }

            //指示器
            .txt_indicator {
                margin-top: 16px;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>